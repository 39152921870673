/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";


abp-tenant-box,
.btn-group.ms-auto.dropdown,
.copyright{
  display: none;
}

/* Fixed header spacing */
body {
  padding-top: calc(var(--header-height));
  
  @media (max-width: 991px) {
    padding-top: var(--header-height-mobile);
  }
}

/*
 * Font Size System
 * ----------------
 * This application uses a relative font size system based on rem units.
 * The base font size is defined in the :root element as --bs-body-font-size.
 * 
 * To adjust all font sizes throughout the application:
 * 1. Change the --bs-body-font-size value in :root
 * 2. All font sizes using rem units will scale proportionally
 * 
 * Media queries are used to adjust the base font size for different screen sizes:
 * - Mobile (max-width: 768px): 14px
 * - Default: 16px
 * - Large screens (min-width: 1200px): 18px
 */

/* Global Variables */
:root {
  /* Header Component Variables */
  --header-logo-width: 140px;
  --header-height: 80px;
  --header-height-mobile: 60px;
  --transition-speed: 0.3s;
  --box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  --font-family: 'Roboto Condensed', sans-serif;
}

 
:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/logo-dark.png');
  --lpx-logo-icon: url('/assets/images/logo/icon-dark.png');
  --lpx-primary-color: #cf2129;
  --lpx-theme-light-bg: url('/assets/images/login_bg.svg');
  --bs-gutter-y: 20px
  --bs-btn-hover-color: #cf2129;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #cf2129;
  --bs-link-color: #cf2129;
  --lpx-navbar-bg: #fff;
  --lpx-footer-bg: #cf2129;
  --lpx-text-reverse-color: #fff;
}

:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/logo-dark.png');
  --lpx-logo-icon: url('/assets/images/logo/icon-dark.png');
  --lpx-primary-color: #cf2129;
  --lpx-theme-light-bg: url('/assets/images/login_bg.svg');
  --bs-gutter-y: 20px
  --bs-btn-hover-color: #cf2129;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #cf2129;
  --bs-link-color: #cf2129;
  --lpx-navbar-bg: #fff;
  --lpx-footer-bg: #cf2129;
  --lpx-text-reverse-color: #fff;
}

/* Ensure HTML and body use the root font size */
html {
  font-size: var(--bs-body-font-size);
}

/* Language-specific font size adjustments */
html[lang="hi"] {
  /* Set a moderate font size increase for Hindi */
  font-size: 18px !important; /* Approximately 12.5% larger than default 16px */
  
  /* Add some specific adjustments that won't compound */
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.3; /* Better line height for Hindi headings */
  }
  
  .main-menu-de ul.navbar-nav li.nav-item a {
    letter-spacing: 0.02rem; /* Slightly increased letter spacing */
  }
}

body {
  font-size: 1rem; /* This will be relative to the html font-size */
  font-family: var(--font-family);
  line-height: normal;
}

/* Responsive font sizing */
@media (max-width: 768px) {
  :root {
    --bs-body-font-size: 14px;  // Smaller base size on mobile
  }
}

@media (min-width: 1200px) {
  :root {
    --bs-body-font-size: 18px;  // Larger base size on large screens
  }
}

/* 
  Font size conversion guide:
  - 1rem = var(--bs-body-font-size) (default: 16px)
  - To convert px to rem: [px value] / 16
  
  Common conversions:
  - 12px = 0.75rem
  - 14px = 0.875rem
  - 16px = 1rem
  - 18px = 1.125rem
  - 20px = 1.25rem
  - 24px = 1.5rem
  - 32px = 2rem
  - 48px = 3rem
*/

// Font size utility classes
.fs-xs { font-size: 0.75rem; }    // 12px
.fs-sm { font-size: 0.875rem; }   // 14px
.fs-base { font-size: 1rem; }     // 16px
.fs-md { font-size: 1.125rem; }   // 18px
.fs-lg { font-size: 1.25rem; }    // 20px
.fs-xl { font-size: 1.5rem; }     // 24px
.fs-2xl { font-size: 2rem; }      // 32px
.fs-3xl { font-size: 3rem; }      // 48px

// Language-specific utility classes
html[lang="hi"] {
  .hindi-larger { font-size: 1.2em; } // 20% larger for specific Hindi elements
  .hindi-spacing { letter-spacing: 0.05em; } // Increased letter spacing for Hindi
  .hindi-line-height { line-height: 1.6; } // Increased line height for Hindi
}


.test-site-warning {
  font-size: 1.5rem;
  background: red;
  color: white;
  text-align: center;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: #FFF !important;
}

.primary-color{
  color: var(--lpx-primary-color);
}

a {
  color: var(--lpx-primary-color);
  text-decoration: underline;
}
.btn-primary{
  --bs-btn-hover-color: var(--lpx-primary-color);
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: var(--lpx-primary-color);
}

.btn-outline-primary {
  padding: 5px 20px;
  border-radius: 20px;
  border: 1px solid var(--lpx-primary-color);
  color: var(--lpx-primary-color);
}

.account-layout button{
  width:auto !important;
}

h1, h2, h3, h4, h5, h6{
  font-family: inherit;
}

.clearall{
  all:initial;
}


.receipt-container{

  max-width: 1020px;
  margin:100px auto;
  font-family: 'Times New Roman', Times, serif;
}

.navbar.navbar-light.bg-light .nav-item .nav-link {
  color: #000 !important;
}

@media print{
  .banner-part{
    display: none;
  }
  .receipt-container{

    margin:0px auto !important;
    font-family: 'Times New Roman', Times, serif;
  }
}


.admin-container{
  display: inline-block;
    margin: 0 20px;
    max-width: 95%;
}
// .confirmation-dialog{
//   width: 90% !important;
// }
// .confirmation-dialog .icon-container {
//   height: 0;
//   padding: 10px;
// }

.slick-slide img{
  padding: 10px;
  border-radius: 2rem;
  // outline: 2px solid #d83565;
}
.receipt-container *{
  color: #000 !important;
}

.receipt-container h1{
  font-size: calc(1rem + 1vw) !important;
  font-weight: bold;
}

.receipt-container h2{
  font-size: calc(0.85rem + 0.85vw) !important;
  font-weight: bold;
}

.receipt-container h4{
  font-size: calc(0.7rem + 0.7vw) !important;
  
}

.ml-10{
  margin-left:10px;
}

.ml30{
  margin-left: 30px;
}

.mt30{
  margin-top:30px;
}

.mt-40{
  margin-top:40px !important;
}

.mb-20{
  margin-bottom: 20px;
}
.mb-30{
  margin-bottom: 30px;
}

.mb-40{
  margin-bottom: 40px;
}

.mr-20{
  margin-right: 20px;
}

.no-padding{
  padding: 0 !important;
}

.text-ellipsis{
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.p-button{
  background-color: var(--lpx-primary-color);
  border: 1px solid var(--lpx-primary-color);
}
.p-blockui{
  z-index: 1000;
  height: 100dvh !important;
}

/* Enhanced ABP Loader Styles */
.abp-loading {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  

  .abp-spinner {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    background: rgba(255, 255, 255, 0.9) !important;
    padding: 2rem !important;
    border-radius: 1rem !important;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1) !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 1rem !important;
    color: var(--lpx-primary-color) !important;
    font-size: 3rem !important;
    animation: pulse 1.5s ease-in-out infinite !important;

    &::after {
      content: "Loading..." !important;
      font-size: 1rem !important;
      margin-top: 1rem !important;
      color: var(--lpx-primary-color) !important;
      font-weight: 500 !important;
      letter-spacing: 0.05em !important;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.bg-primary{
  background-color: var(--lpx-primary-color) !important;
  color: #FFF !important;
}
.bg-primary a,
.bg-primary h2{
  color: #FFF !important;
}
.navbar-verticle{
  flex-direction:column !important;
  padding: 5px 10px;
}
.navbar-verticle li{
  padding: 10px
}

ul.navbar-nav.navbar-verticle a{
  color:#010101;
}
.slick-prev{
  z-index: 2;
  left:25px !important;
}
.slick-prev::before,
.slick-next::before{
  content: '';
  display: none;
}

.slick-next{
  z-index: 2;
    right: 50px !important;
}
.container-small{
  width: 70%;
  margin: auto;
}

.cursor-pointer{
  cursor: pointer;
}


.btn-primary,
.header-btn .btn-default {
  background: var(--lpx-primary-color);
  --bs-btn-active-color:var(--lpx-primary-color);
  --bs-btn-active-bg : #FFF;
  --bs-btn-active-border-color: var(--lpx-primary-color);

  color: #ffffff;
  border-radius: 25px;
  padding: 6px 25px;
  border: 1px solid var(--lpx-primary-color);
  font-size: 0.9375rem;
  margin-left:30px;
}

.button-more .btn-primary{
  margin-left: 10px;
}

.bot-card .button-more.small-button-more a.btn{
padding: 5px 10px;
font-size: 0.6875rem;
}

.btn-outline-secondary{
  color: var(--lpx-primary-color);
  border-color: var(--lpx-primary-color);
  background-color: #FFF;
}


a{
  text-decoration: none;
  cursor: pointer;
}

a:hover{
  text-decoration: none;
}

.h250{
  height: 350px;
}

.confirmation{
  z-index: 12000 !important;
}

.navigation-menu > ul {
  margin: 0 !important;
}
.navigation-menu > ul li {
  padding-top: 6px;
}

.p-multiselect {width: 100%;}

.p-dialog .p-dialog-header{
  padding: 0;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 2rem;
  padding: 1rem 2rem;
}

.pac-container{
  z-index: 1100 !important;
}
.event-header{
  font-weight: bold;
  font-size: 1.3em;
}

.event-detail{
  font-weight: bold;
  font-size: 1em;
  border: 1px solid var(--lpx-primary-color);
  border-radius: 20px;
  background-color: var(--lpx-primary-color);
  padding: 5px 12px;
  color: #FFF;
  ;

}

.member-services .single-foundation{
  height: 200px;
}
.single-donation-wrap{
  margin-bottom: 40px;
}
.donation-image{
  text-align: right;
  vertical-align: top;
}
.donation-image img{
  border-radius: 20px;
  // max-width: 100px;
}

.align-item-start{
  align-items: start !important
}

.card-max-content-height{
  max-height: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.event-card .p-card .p-card-content{
  padding-top: 5px;
}

.event-card .p-card{
  margin-bottom: 2em;
  margin-right: 2em;
  height: 20vb;
}

.p-card .p-card-body{
  overflow: hidden;
}

.cards img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 25%;
  }
}


.card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.card_content {
  padding: 1rem;
  background: linear-gradient(to bottom left, #FF7722 40%, #fdb588 100%);
}


.custom-control input{
  box-shadow: none;
    border-color: #e8eef3;
    color: #062a44;
  display: block;
    width: 100%;
    padding: 0.675rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5;
    color: #325168;
    background-color: #f0f4f7;
    border: 1px solid #e8eef3;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 1rem;
}

.card_title {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0 0 10px 0;
}
.card_subtitle{
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
}
.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}

.event_cards .cards_item .card{
  background: linear-gradient(to bottom left, #FF7722 40%, #fdb588 100%);
}

.cards_item .card_content{
  background: initial;
}

.contact-cards {
  padding: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-evenly;
    gap: 1em;
}

.contact-cards .cards_item{
  width: 33.33%;
}

.contact-cards .card{
  flex-grow: 1;
}

#events ul li .title {
  //color: var(--lpx-primary-color);
  color:#1B5F8C;
  padding-block-start:2.5em;
}

.top_nav{
  display: none;
}


.reset-to-initial{
  all:initial;
}

.reset-to-initial ul li{
  list-style-type: disc;
}

.select-options{
  border: 1px solid #919191;
  border-radius: 20px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.select-options.disabled{
  background-color: #919191;
}


@media only screen and (max-width: 767px){
  .top_nav{
    display: block;
    position: absolute;
    top: 10px;
    left:10px;
    color: #FFF;
    font-weight: bold;
    z-index: 1200;
    width: calc(100vw - 24px);
  }
  .lpx-sidebar-container, header, .lpx-toolbar-container {
    display: block !important;
  }
  .btn.mobile-button{
    font-size: 0.8em;
  }
}

@media (min-width: 768px){
  .container-md, .container-sm, .container {
      max-width: 95%;
  }
}

@media (max-width: 1024px){
  .main-menu-de ul.navbar-nav li a {
      text-transform: uppercase;
      font-size: 0.75rem;
      color: #000 !important;
      padding: 0px 20px 10px !important;
  }
}


.p-calendar{
  width: 100% !important;
}

p-calendar{
  width: 100% !important;
}

.p-autocomplete{
  width: 100%;
}
.p-dropdown{
  width: 100%;
}
.p-overlay{
  z-index: 1066 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-listMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-listMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-listMonth-button{
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-listMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-listMonth-button.fc-button-active:hover, 
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-listMonth-button.fc-button-active:hover, .fc-button-active{
  background: #2563eb !important;
  border-color: #2563eb !important;
  color: #ffffff !important;
}

/* Language-specific font settings */
html[lang="en"] {
  font-family: 'Roboto', Arial, sans-serif;
}

/* Hindi font settings */
html[lang="hi"],
html[lang="hi"] h1,
html[lang="hi"] h2,
html[lang="hi"] h3,
html[lang="hi"] h4,
html[lang="hi"] h5,
html[lang="hi"] h6,
html[lang="hi"] p,
html[lang="hi"] span,
html[lang="hi"] a,
html[lang="hi"] button,
html[lang="hi"] input,
html[lang="hi"] label,
html[lang="hi"] select,
html[lang="hi"] textarea {
  font-family: 'Noto Sans Devanagari', 'Hind', Arial, sans-serif;
  line-height: 1.5;
}

/* Gujarati font settings */
html[lang="gu"],
html[lang="gu"] h1,
html[lang="gu"] h2,
html[lang="gu"] h3,
html[lang="gu"] h4,
html[lang="gu"] h5,
html[lang="gu"] h6,
html[lang="gu"] p,
html[lang="gu"] span,
html[lang="gu"] a,
html[lang="gu"] button,
html[lang="gu"] input,
html[lang="gu"] label,
html[lang="gu"] select,
html[lang="gu"] textarea {
  font-family: 'Noto Sans Gujarati', 'Hind', Arial, sans-serif;
  line-height: 1.5;
}

/* Punjabi font settings */
html[lang="pa"],
html[lang="pa"] h1,
html[lang="pa"] h2,
html[lang="pa"] h3,
html[lang="pa"] h4,
html[lang="pa"] h5,
html[lang="pa"] h6,
html[lang="pa"] p,
html[lang="pa"] span,
html[lang="pa"] a,
html[lang="pa"] button,
html[lang="pa"] input,
html[lang="pa"] label,
html[lang="pa"] select,
html[lang="pa"] textarea {
  font-family: 'Noto Sans Gurmukhi', 'Hind', Arial, sans-serif;
  line-height: 1.5;
}

/* Add Google Fonts link in angular.json or index.html for these fonts */

/* Enhanced ABP Loader Bar Styles */

.abp-loader-bar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  //height: 100% !important;
  z-index: 0 !important;
  background: rgba(255, 255, 255, 0.7) !important;
  
  -webkit-backdrop-filter: blur(4px) !important;
  backdrop-filter: blur(4px) !important;
  

  &.is-loading {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 9999 !important;
  }

  .abp-progress {
    position: fixed !important;
    top: 0 !important;
    
    height: 40px !important;
    
    border-radius: 2px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    
    &.progressing {
      animation: shimmer 1.5s infinite !important;
      background: linear-gradient(
        90deg,
        var(--lpx-primary-color) 0%,
        rgba(207, 33, 41, 0.6) 50%,
        var(--lpx-primary-color) 100%
      );
      background-size: 200% 100% !important;
    }

    &::after {
      content: "Loading..." !important;
      position: absolute !important;
      top: 15px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      color: #FFF !important;
      font-size: 0.875rem !important;
      font-weight: 500 !important;
      white-space: nowrap !important;
    }
  }
}

@keyframes blurAnimation {
  0% {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
  100% {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
